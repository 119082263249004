@import 'font.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  //border: 5px blue dashed;
}

html {
  margin: 0;
  font-size: 16px;
  
}

body {
  min-height: 100vh;
  width: 100%;
  //border: 5px red dashed;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
