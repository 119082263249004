@import 'font.scss';
@import 'color.scss';

.limited-access-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    row-gap: 30px;
}

.countdown {
    font-family: 'Body', sans-serif;
    font-size: 50px;
    color: $dark-gray;
}

.countdown-container {
    font-family: 'Heading3', sans-serif;
    font-size: 20px;
}

.enter-password {
    cursor: pointer;
}


.flower-image {
    width: 40%;
    height: auto;
}

.email-input {
    box-sizing: border-box;
    font-family: 'Body', sans-serif;
    padding: 5px 10px;
    width: 100%;
}

.email-button-style {
    font-family: 'Body', sans-serif;
    margin-left: 15px;
    height: 48px;
    padding: 0 20px;
    background-color: $dark-gray;
    color: $white;
}

.email-container {
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
}