@font-face {
    font-family: 'Body';
    src: url('../font/PixelOperator.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
}

@font-face{
    font-family: 'Heading3';
    src: url('../font/PixelOperator.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
    font-size: 18px;
}

@font-face {
    font-family: 'Heading2';
    src: url('../font/PixelSansSerifCondensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
    font-size: 22px;
}

@font-face {
    font-family: 'Heading1';
    src: url('../font/PixelSansSerifCondensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
    font-size: 24px;
}